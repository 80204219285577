<template>
  <div class="table-component stock">
    <div class="table-vidjet">
      <table>
        <thead>
          <colgroup width="30%"></colgroup>
          <colgroup width="15%"></colgroup>
          <colgroup width="25%"></colgroup>
          <colgroup width="10%"></colgroup>
          <colgroup width="25%"></colgroup>
          <tr>
            <th>Содержание</th>
            <th>Срок</th>
            <th>Исполнитель</th>
            <th>Статус</th>
            <th>Отчет</th>
          </tr>
        </thead>
        <tbody class="mh380">
          <tr v-for="item in items" :key="item.id" @dblclick="viewCard(item.assignmentId)">
            <td width="30%" :title="item.assignmentBrief">
              {{ item.assignmentBrief }}
            </td>
            <td width="15%" :title="item.assignmentSrok">
              {{ item.assignmentSrok }}
            </td>
            <td width="25%" :title="item.organizationName">
              {{ item.organizationName }}
            </td>
            <td width="10%" :title="item.statusName">
              <IconComponent v-if="isDone(item.statusId)" name="goodSigh" />
              <IconComponent v-else-if="item.overDue || item.postponed" name="banSigh" />
              <IconComponent v-else name="refreshSigh" />
            </td>
            <td width="25%" :title="item.reportTotal">
              {{ item.reportTotal }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-component__footer">
      <ButtonStock title="Все поручения" @click="goTo" />
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/widgetElement';

  export default {
    name: 'Widget9',
    components: {
      IconComponent,
      ButtonStock,
    },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    watch: {
      countryId: function (/*newValue*/) {
        this.loadItems();
      },
    },
    methods: {
      loadItems() {
        API.getMpkAssignment({ countryId: this.countryId }).then((response) => {
          this.items = response.data;
        });
      },
      viewCard(id) {
        this.$router.push({ name: 'AssignmentCard', params: { id: id, action: 'edit' } });
      },
      goTo() {
        this.$router.push({ name: 'Assignments' });
      },
      isDone(statusId) {
        return statusId == Constants.mpkAssignemntStatusDoneId;
      },
    },
    data() {
      return {
        items: [],
      };
    },
    created() {
      this.loadItems();
    },
  };
</script>

<style lang="scss" scoped></style>
